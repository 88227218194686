<button class="print-button" mat-raised-button (click)="print()" color="primary" style="margin-bottom: 10px;"><mat-icon>print</mat-icon>Print</button>
<div *ngIf="!(currentAuto.Owners.length > 0); else ownersExist">
    <p style="text-align: center; font-size: 20px;margin-bottom: 10px;">There are no owners for this vehicle</p>
</div>
<ng-template #ownersExist>
<mat-card class="-for-lien-foreclosure-form small-form hide-in-print" style="margin-bottom: 30px;">
    <!-- <h1 class="title">{{ title }}</h1> -->
    <form [formGroup]="form" (ngSubmit)="onSubmit($event)" novalidate>
        <mat-card-content>
            <div class="row gutters">
                <div class="col">
                    <mat-form-field>
                        <input matInput [matDatepicker]="DateFiled" placeholder="Filed Date" formControlName="DateFiled">
                        <mat-datepicker-toggle matSuffix [for]="DateFiled"></mat-datepicker-toggle>
                        <mat-datepicker #DateFiled></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field>
                        <input matInput [matDatepicker]="DatePetitionFiled" placeholder="Petition Filed Date" formControlName="DatePetitionFiled">
                        <mat-datepicker-toggle matSuffix [for]="DatePetitionFiled"></mat-datepicker-toggle>
                        <mat-datepicker #DatePetitionFiled></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <div class="row gutters">
                <div class="col">
                    <mat-form-field>
                        <input matInput type="number" placeholder="Lien Amount" formControlName="LienAmount">
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field>
                        <input matInput [matDatepicker]="LienDate" placeholder="Lien Date" formControlName="LienDate">
                        <mat-datepicker-toggle matSuffix [for]="LienDate"></mat-datepicker-toggle>
                        <mat-datepicker #LienDate></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
			<div class='row gutters'>
				<div class='col'>
					<mat-form-field>
						<input type='number' matInput placeholder='Removal (Towing) Fee' formControlName='RemovalFee'>
					</mat-form-field>
				</div>
				<div class='col'>
					<mat-form-field>
						<input type='number' matInput placeholder='Storage' formControlName='Storage'>
					</mat-form-field>
				</div>
			</div>
			<div class='row gutters'>
				<div class='col'>
					<mat-form-field>
						<input type='number' matInput placeholder='Repair Fees (if applicable)' formControlName='RepairFees'>
					</mat-form-field>
				</div>
				<div class='col'>
					<mat-form-field>
						<input type='number' matInput placeholder='Cost of Obtaining Owner Information' formControlName='OwnerInfoCost'>
					</mat-form-field>
				</div>
			</div>
			<div class='row gutters'>
				<div class='col'>
					<mat-form-field>
						<input type='number' matInput placeholder='Notification/Advertising Costs' formControlName='AdvertisingCosts'>
					</mat-form-field>
				</div>
				<div class='col'>
					<mat-form-field>
						<input type='number' matInput placeholder="Attorney's Fees (if applicable)" formControlName='AttorneyFees' />
					</mat-form-field>
				</div>
			</div>
			<div class='row gutters'>
				<div class='col'>
					<mat-form-field>
						<input type='number' matInput placeholder='Prejudgement Interest' formControlName='PrejudgementInterest'>
					</mat-form-field>
				</div>
				<div class='col'>
					<mat-form-field>
						<input type='number' matInput placeholder='Court Costs/Certified Copies' formControlName='CourtCosts'>
					</mat-form-field>
				</div>
			</div>

        </mat-card-content>
        <mat-card-actions end>
        </mat-card-actions>
    </form>
</mat-card>
<div id="print-section" style="position: relative;">
    <div class="print-out-container page" *ngFor="let owner of currentAuto.Owners">
        <div class="amv-lien-foreclosure">
            <p style="page-break-before: always; margin-bottom: 0;"></p>

<div class="motion" style="position: relative; padding: 0; height: 822px; box-shadow: none;">
<html>
<head><meta http-equiv=Content-Type content="text/html; charset=UTF-8">
<style type="text/css">
 span.cls_003{font-family:Times,serif;font-size:14.1px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none}
 div.cls_003{font-family:Times,serif;font-size:14.1px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none}
 span.cls_002{font-family:Times,serif;font-size:10.0px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
 div.cls_002{font-family:Times,serif;font-size:10.0px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
 tr.cls_002{font-family:Times,serif;font-size:10.0px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none}
 span.cls_006{font-family:Times,serif;font-size:10.0px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none}
 div.cls_006{font-family:Times,serif;font-size:10.0px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none}
 span.cls_008{font-family:Times,serif;font-size:10.0px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: underline}
 div.cls_008{font-family:Times,serif;font-size:10.0px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none}
 span.cls_007{font-family:Times,serif;font-size:12.1px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}
 div.cls_007{font-family:Times,serif;font-size:12.1px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}

 td.lien-line-item{width: 21%; padding-top: 2px;padding-bottom: 2px;}
 td.lien-line-value{width: 35%;padding-top: 2px;padding-bottom: 2px;}
 .lien-line-value span {display: inline-block; width: 65px; padding-left:6px; border-bottom: 1px solid black; font-weight: normal;}
 td.before-spacer {width:4%;}
 td.between-spacer {width: 12%;}

</style>
<script type="text/javascript" src="cb70209a-f668-11e9-9d71-0cc47a792c0a_id_cb70209a-f668-11e9-9d71-0cc47a792c0a_files/wz_jsgraphics.js"></script>
</head>
<body>

	<div style="position:absolute;left:50%;margin-left:-306px;top:0px;width:612px;height:792px;border-style:outset;overflow:hidden">

    <div style="position:absolute;right:50px;top:20px" class="cls_002"><span class="cls_002">{{ fields.AutoNumber }}</span></div>
    <div style="position:absolute;left:278px;top:35.04px" class="cls_003"><span class="cls_003">{{ fields.CompanyCounty }}</span></div>
<div style="position:absolute;left:101.16px;top:35.04px" class="cls_003"><span class="cls_003">MAGISTRATE COURT OF ______________COUNTY, GEORGIA</span></div>
<div style="position:absolute;left:82px;top:63.00px" class="cls_002"><span class="cls_002">{{ form.get('DateFiled').value | date:'shortDate':'utc' }}</span></div>
<div style="position:absolute;left:36.00px;top:63.00px" class="cls_002"><span class="cls_002">Date Filed ________________</span></div>
<div style="position:absolute;left:366px;top:63.00px" class="cls_002"><span class="cls_002">{{ fields.AutoCaseNumber }}</span></div>
<div style="position:absolute;left:324.00px;top:63.00px" class="cls_002"><span class="cls_002">Case No: _______________</span></div>
<div style="position:absolute;left:35.84px;top:88.32px" class="cls_002"><span class="cls_002">{{ fields.CompanyName }}</span></div>
<div style="position:absolute;left:35.84px;top:88.32px" class="cls_002"><span class="cls_002">____________________________________________</span></div>
<div style="position:absolute;left:35.84px;top:105.60px" class="cls_002"><span class="cls_002">{{ fields.CompanyAddress }}</span></div>
<div style="position:absolute;left:35.84px;top:105.60px" class="cls_002"><span class="cls_002">____________________________________________</span></div>
<div style="position:absolute;left:35.84px;top:122.76px" class="cls_002"><span class="cls_002">{{ fields.CompanyCityStateZip }}</span></div>
<div style="position:absolute;left:35.84px;top:122.76px" class="cls_002"><span class="cls_002">____________________________________________</span></div>
<div style="position:absolute;left:35.84px;top:139.83px" class="cls_006"><span class="cls_006">Plaintiff(s) Name, Address</span></div>
<div style="position:absolute;left:287.97px;top:88.32px" class="cls_002"><span class="cls_002">{{ getName(owner.Company, owner.FirstName, owner.LastName) }}</span></div>
<div style="position:absolute;left:287.97px;top:88.32px" class="cls_002"><span class="cls_002">____________________________________________________</span></div>
<div style="position:absolute;left:287.97px;top:105.60px" class="cls_002"><span class="cls_002">{{ getAddress(owner.Address1, owner.Address2) }}</span></div>
<div style="position:absolute;left:287.97px;top:105.60px" class="cls_002"><span class="cls_002">____________________________________________________</span></div>
<div style="position:absolute;left:287.97px;top:122.76px" class="cls_002"><span class="cls_002">{{ getCityStateZip(owner.City,owner.State, owner.Zip) }}</span></div>
<div style="position:absolute;left:287.97px;top:122.76px" class="cls_002"><span class="cls_002">____________________________________________________</span></div>
<div style="position:absolute;left:287.97px;top:140.04px" class="cls_006"><span class="cls_006">Vehicle Owner Name, Address</span></div>
<div style="position:absolute;left:265.04px;top:148.29px" class="cls_002"><span class="cls_002">vs</span></div>
<div style="position:absolute;left:35.84px;top:163.08px" class="cls_002"><span class="cls_002">{{ fields.AutoYearMakeModel }}</span></div>
<div style="position:absolute;left:35.84px;top:163.08px" class="cls_002"><span class="cls_002">____________________________________________</span></div>
<div style="position:absolute;left:35.84px;top:180.24px" class="cls_002"><span class="cls_002">{{ fields.AutoTagState }}</span></div>
<div style="position:absolute;left:35.84px;top:180.24px" class="cls_002"><span class="cls_002">____________________________________________</span></div>
<div style="position:absolute;left:35.84px;top:197.52px" class="cls_002"><span class="cls_002">{{ fields.AutoVIN }}</span></div>
<div style="position:absolute;left:35.84px;top:197.52px" class="cls_002"><span class="cls_002">____________________________________________</span></div>
<div style="position:absolute;left:36.00px;top:214.80px" class="cls_006"><span class="cls_006">Motor Vehicle Description and VIN</span></div>
<div style="position:absolute;left:287.97px;top:163.08px" class="cls_002"><span class="cls_002">{{ fields.Lienholder1Name }}</span></div>
<div style="position:absolute;left:287.97px;top:163.08px" class="cls_002"><span class="cls_002">____________________________________________________</span></div>
<div style="position:absolute;left:287.97px;top:180.24px" class="cls_002"><span class="cls_002">{{ fields.Lienholder2Name }}</span></div>
<div style="position:absolute;left:287.97px;top:180.24px" class="cls_002"><span class="cls_002">____________________________________________________</span></div>
<div style="position:absolute;left:287.97px;top:197.52px" class="cls_002"><span class="cls_002">____________________________________________________</span></div>
<div style="position:absolute;left:287.97px;top:214.80px" class="cls_006"><span class="cls_006">Lien Holders Notified</span></div>



<div style="position:absolute;left:146.87px;top:237.73px" class="cls_006"><span class="cls_006">MOTION FOR ORDER ON MOTOR VEHICLE LIEN FORECLOSURE</span></div>
<div style="position:absolute;left:36.00px;top:249.24px" class="cls_008"><span class="cls_008">____________________________________________________________________________________________________________</span></div>
<div style="position:absolute;left:36.00px;top:272.28px" class="cls_002"><span class="cls_002">NOW COMES PETITIONER, a [ {{ fields.Mark }} ] towing or storage facility, [ ] repair facility, [ ] salvage dealer, and shows the following:</span></div>
<div style="position:absolute;left:393px;top:295.21px" class="cls_002"><span class="cls_002">{{ form.get('DatePetitionFiled').value | date:'shortDate':'utc' }}</span></div>
<div style="position:absolute;left:36.10px;top:295.21px" class="cls_002"><span class="cls_002">1. A petition was filed to foreclose on the lien placed on the above-referenced vehicle on ___________________</span></div>
<div style="position:absolute;left:36.10px;top:318.26px" class="cls_002"><span class="cls_002">2. Service of a copy of the oath or affirmation page of the filed petition and of a copy of the answer form was perfected on all owners</span></div>
<div style="position:absolute;left:35.99px;top:329.78px" class="cls_002"><span class="cls_002">by [ ] certified mail, return receipt requested [ ] advertisement (see attached); and</span></div>
<div style="position:absolute;left:36.10px;top:352.83px" class="cls_002"><span class="cls_002">3. No answer has been filed as required by OCGA § 40-11-19.1 (c).</span></div>
<div style="position:absolute;left:35.99px;top:375.76px" class="cls_002"><span class="cls_002">WHEREFORE, PETITIONER PRAYS that (a) a judgment be issued declaring the vehicle to be abandoned and the lien to be valid,</span></div>
<div style="position:absolute;left:35.99px;top:387.28px" class="cls_002"><span class="cls_002">and (b) an order be issued authorizing public sale of the vehicle to satisfy the lien as prayed for in the complaint.</span></div>
<div style="position:absolute;left:35.99px;top:410.33px" class="cls_002"><span class="cls_002">Sworn to and Subscribed before me</span></div>
<div style="position:absolute;left:58px;top:433.38px" class="cls_002"><span class="cls_002">{{ form.get('DateFiled').value | date:'d':'utc' }}</span></div>
<div style="position:absolute;left:107px;top:433.38px" class="cls_002"><span class="cls_002">{{ form.get('DateFiled').value | date:'MMMM':'utc' }}</span></div>
<div style="position:absolute;left:180px;top:433.38px" class="cls_002"><span class="cls_002">{{ form.get('DateFiled').value | date:'yy':'utc' }}</span></div>
<div style="position:absolute;left:35.94px;top:433.38px" class="cls_002"><span class="cls_002">This ____ day of_____________20_____</span></div>
<div style="position:absolute;left:36.00px;top:456.96px" class="cls_002"><span class="cls_002">Notary Public or Clerk</span></div>
<div style="position:absolute;left:287.93px;top:456.30px" class="cls_002"><span class="cls_002">Authorized Signature: __________________________</span></div>
<div style="position:absolute;left:36.06px;top:468.41px" class="cls_002"><span class="cls_002">My Commission Expires: _____________</span></div>
<div style="position:absolute;left:36.00px;top:690px" class="cls_007"><span class="cls_007">MAG 40-11</span></div>


</div><!-- end of page 2 -->
</body>
</html>
</div>

<p style="page-break-before: always; margin-bottom: 0;"></p>
<!-- <p style="page-break-after: always; margin-bottom: 0;"></p> -->

<!-- <div class="print-out court-order" style="position: relative;">
	 <p style="position:absolute; top: 30px; right: 40px;">{{ fields.AutoNumber }}</p>
	 <p style="text-align: center;">IN THE MAGISTRATE COURT OF CLAYTON COUNTY</p>
	 <p style="text-align: center;">STATE OF GEORGIA</p>
	 <div class="row" style="padding: 20px 0;">
	 <div class="col" style="margin-top: auto; margin-bottom: auto; padding: 20px 40px; border-right: 1px solid black;">
	 <p>Plaintiff, <span class="bold-underline">{{ fields.CompanyName }}</span></p>
	 <p>vs.</p>
	 <p style="margin-bottom: 0;">Defendant, <span class="bold-underline">{{ fields.OwnerName }}</span>
	 <span *ngIf="fields.Lienholder1Name">, <span class="bold-underline">{{ fields.Lienholder1Name }}</span></span>
	 <span *ngIf="fields.Lienholder2Name">, <span class="bold-underline">{{ fields.Lienholder2Name }}</span></span>
	 </p>
	 </div>
	 <div class="col" style="margin-top: auto; margin-bottom: auto; padding-left: 40px;padding-right: 40px;">
	 <p>CASE No.: <span *ngIf="isBlank">_______________________</span><span *ngIf="!isBlank" class="bold-underline">{{ fields.AutoCaseNumber }}</span></p>
	 <p style="margin-bottom: 0;">Foreclosure of Lien on Abandoned Motor Vehicle</p>
	 </div>
	 </div>
	 <p class="bold-underline" style="text-align: center;">Order</p>
	 <p><span style="padding-left: 40px;"></span>The Plaintiff having demonstrated to the Court that the following vehicle:</p>
	 <div class="row">
	 <div class="col">
	 <p *ngIf="isBlank" style="text-align: center; border-top: 1px solid black;">(Year)</p>
	 <p class="bold-underline" *ngIf="!isBlank" style="text-align: center; margin-bottom: 0;">{{ fields.AutoYear }}</p>
	 <p *ngIf="!isBlank" style="text-align: center;">(Year)</p>
	 </div>
	 <div class="col">
	 <p *ngIf="isBlank" style="text-align: center; border-top: 1px solid black;">(Make)</p>
	 <p class="bold-underline" *ngIf="!isBlank" style="text-align: center; margin-bottom: 0;">{{ fields.AutoMake }}</p>
	 <p *ngIf="!isBlank" style="text-align: center;">(Make)</p>
	 </div>
	 <div class="col">
	 <p *ngIf="isBlank" style="text-align: center; border-top: 1px solid black;">(Model)</p>
	 <p class="bold-underline" *ngIf="!isBlank" style="text-align: center; margin-bottom: 0;">{{ fields.AutoModel }}</p>
	 <p *ngIf="!isBlank" style="text-align: center;">(Model)</p>
	 </div>
	 <div class="col">
	 <p *ngIf="isBlank" style="text-align: center; border-top: 1px solid black;">(Vehicle Identification Number)</p>
	 <p class="bold-underline" *ngIf="!isBlank" style="text-align: center; margin-bottom: 0;">{{ fields.AutoVIN }}</p>
	 <p *ngIf="!isBlank" style="text-align: center;">(Vin)</p>
	 </div>
	 </div>
	 <p>is an abandoned vehicle as described in O.C.G.A. S40-11-1(1) and subject to a lien pursuant to ).C.G.A. S40-11-4; and</p>
	 <p><span style="padding-left: 40px;"></span>The Plaintiff having submitted an affidavit as required in O.C.G.A. S40-11-5(2) wherein compliance with the requirements of said statue for the foreclosure of said lien has been demonstrated; and</p>
	 <p><span style="padding-left: 40px;"></span>No timely petition for a hearing has been filed with the Court or Plaintiff has otherwise proven by a preponderance of the evidence that such lien is valid;</p>
	 <p class="bold"><span style="padding-left: 40px;"></span>IT IS HEREBY ORDERED THAT:</p>
	 <p><span style="padding-left: 40px;"></span>The lien be established on the above described vehicle in the amount of <span *ngIf="isBlank">_____</span><span class="bold-underline" *ngIf="!isBlank">{{ form.get('LienAmount').value | currency }}</span> through <span *ngIf="isBlank">__________</span><span class="bold-underline" *ngIf="!isBlank">{{ form.get('LienDate').value | date:'shortDate':'UTC' }}</span>, in accordance with O.C.G.A. S40-11-6 governing Abandoned Motor Vehicles; that foreclosure thereof be allowed through public sale in accordance with the terms and provisions of O.C.G.A. S40-11-6 and S40-11-201, that the vehicle be sold free and clear of any and all liens and encumbrances. ( ) The Plaintiff is entitled to additional storage charges through the date of sale at the rate of <span *ngIf="isBlank">_____</span><span class="bold-underline" *ngIf="!isBlank">{{ fields.StoragePerDay | currency }}</span> per day.</p>
	 <p>    Within thirty (30) days of the date of such sale, the Plaintiff shall file a report to the Court in compliance with O.C.G.A. S40-11-6.</p>
	 <p><span style="padding-left: 40px;"></span>SO ORDERED this ___ day of ___________, ______.</p>
	 <div>
	 <p style="text-align: right;"><span style="border-top: 1px solid black;">Judge, Magistrate Court of Clayton County</span></p>
	 </div>
	 </div> -->


<div class="motion" style="position: relative; padding: 0; height: 822px; box-shadow: none;">
	<html><body>
		<div style="position:absolute;left:50%;margin-left:-306px;top:0px;width:612px;height:792px;border-style:outset;overflow:hidden">

			<div style="position:absolute;right:50px;top:20px" class="cls_002"><span class="cls_002">{{ fields.AutoNumber }}</span></div>
			<div style="position:absolute;left:278px;top:35.04px" class="cls_003"><span class="cls_003">{{ fields.CompanyCounty }}</span></div>
			<div style="position:absolute;left:101.16px;top:35.04px" class="cls_003"><span class="cls_003">MAGISTRATE COURT OF ______________COUNTY, GEORGIA</span></div>
			<div style="position:absolute;left:82px;top:63.00px" class="cls_002"><span class="cls_002">{{ form.get('DateFiled').value | date:'shortDate':'utc' }}</span></div>
			<div style="position:absolute;left:36.00px;top:63.00px" class="cls_002"><span class="cls_002">Date Filed ________________</span></div>
			<div style="position:absolute;left:366px;top:63.00px" class="cls_002"><span class="cls_002">{{ fields.AutoCaseNumber }}</span></div>
			<div style="position:absolute;left:324.00px;top:63.00px" class="cls_002"><span class="cls_002">Case No: _______________</span></div>
			<div style="position:absolute;left:35.84px;top:88.32px" class="cls_002"><span class="cls_002">{{ fields.CompanyName }}</span></div>
			<div style="position:absolute;left:35.84px;top:88.32px" class="cls_002"><span class="cls_002">____________________________________________</span></div>
			<div style="position:absolute;left:35.84px;top:105.60px" class="cls_002"><span class="cls_002">{{ fields.CompanyAddress }}</span></div>
			<div style="position:absolute;left:35.84px;top:105.60px" class="cls_002"><span class="cls_002">____________________________________________</span></div>
			<div style="position:absolute;left:35.84px;top:122.76px" class="cls_002"><span class="cls_002">{{ fields.CompanyCityStateZip }}</span></div>
			<div style="position:absolute;left:35.84px;top:122.76px" class="cls_002"><span class="cls_002">____________________________________________</span></div>
			<div style="position:absolute;left:35.84px;top:139.83px" class="cls_006"><span class="cls_006">Plaintiff(s) Name, Address</span></div>
			<div style="position:absolute;left:287.97px;top:88.32px" class="cls_002"><span class="cls_002">{{ getName(owner.Company, owner.FirstName, owner.LastName) }}</span></div>
			<div style="position:absolute;left:287.97px;top:88.32px" class="cls_002"><span class="cls_002">____________________________________________________</span></div>
			<div style="position:absolute;left:287.97px;top:105.60px" class="cls_002"><span class="cls_002">{{ getAddress(owner.Address1, owner.Address2) }}</span></div>
			<div style="position:absolute;left:287.97px;top:105.60px" class="cls_002"><span class="cls_002">____________________________________________________</span></div>
			<div style="position:absolute;left:287.97px;top:122.76px" class="cls_002"><span class="cls_002">{{ getCityStateZip(owner.City,owner.State, owner.Zip) }}</span></div>
			<div style="position:absolute;left:287.97px;top:122.76px" class="cls_002"><span class="cls_002">____________________________________________________</span></div>
			<div style="position:absolute;left:287.97px;top:140.04px" class="cls_006"><span class="cls_006">Vehicle Owner Name, Address</span></div>
			<div style="position:absolute;left:265.04px;top:148.29px" class="cls_002"><span class="cls_002">vs</span></div>
			<div style="position:absolute;left:35.84px;top:163.08px" class="cls_002"><span class="cls_002">{{ fields.AutoYearMakeModel }}</span></div>
			<div style="position:absolute;left:35.84px;top:163.08px" class="cls_002"><span class="cls_002">____________________________________________</span></div>
			<div style="position:absolute;left:35.84px;top:180.24px" class="cls_002"><span class="cls_002">{{ fields.AutoTagState }}</span></div>
			<div style="position:absolute;left:35.84px;top:180.24px" class="cls_002"><span class="cls_002">____________________________________________</span></div>
			<div style="position:absolute;left:35.84px;top:197.52px" class="cls_002"><span class="cls_002">{{ fields.AutoVIN }}</span></div>
			<div style="position:absolute;left:35.84px;top:197.52px" class="cls_002"><span class="cls_002">____________________________________________</span></div>
			<div style="position:absolute;left:36.00px;top:214.80px" class="cls_006"><span class="cls_006">Motor Vehicle Description and VIN</span></div>
			<div style="position:absolute;left:287.97px;top:163.08px" class="cls_002"><span class="cls_002">{{ fields.Lienholder1Name }}</span></div>
			<div style="position:absolute;left:287.97px;top:163.08px" class="cls_002"><span class="cls_002">____________________________________________________</span></div>
			<div style="position:absolute;left:287.97px;top:180.24px" class="cls_002"><span class="cls_002">{{ fields.Lienholder2Name }}</span></div>
			<div style="position:absolute;left:287.97px;top:180.24px" class="cls_002"><span class="cls_002">____________________________________________________</span></div>
			<div style="position:absolute;left:287.97px;top:197.52px" class="cls_002"><span class="cls_002">____________________________________________________</span></div>
			<div style="position:absolute;left:287.97px;top:214.80px" class="cls_006"><span class="cls_006">Lien Holders Notified</span></div>

			<div style="position:absolute;left:146.87px;top:237.73px" class="cls_006"><span class="cls_006">ORDER ON PETITION FOR ABANDONED MOTOR VEHICLE LIEN FORECLOSURE</span></div>
			<div style="position:absolute;left:36.00px;top:264px" class="cls_002"><span class="cls_002">The above referenced petition coming before this Court for [x] a motion for judgment pursuant to OCGA § 40-11-19.1 (d) [ ] a</span></div>
			<div style="position:absolute;left:36.00px;top:277px" class="cls_002"><span class="cls_002">hearing on the merits pursuant to OCGA § 40-11-19.1 (e), and the Court further finding that the petition should be granted,</span></div>
			<div style="position:absolute;left:36.00px;top:304px" class="cls_002"><span class="cls_002">THIS COURT HEREBY ORDERS that the lien be foreclosed on the above-referenced vehicle for the following amounts:</span></div>
			<table style='position:absolute;left:36px;top:331px;width:100%;' >
				<tr class='cls_002'>
					<td class='before-spacer'></td>
					<td class='lien-line-item'>Removal (Towing) Fee:</td>
					<td class='lien-line-value'>$<span>{{ form.get('RemovalFee').value | currency }}</span></td>
				</tr>
				<tr class='cls_002'>
					<td class='before-spacer'></td>
					<td class='lien-line-item'>Storage:</td>
					<td class='lien-line-value'>$<span>{{ form.get('Storage').value | currency }}</span></td>
				</tr>
				<tr class='cls_002'>
					<td class='before-spacer'></td>
					<td class='lien-line-item'>Repair Fees (if applicable)</td>
					<td class='lien-line-value'>$<span>{{ form.get('RepairFees').value | currency }}</span></td>
				</tr>
				<tr class='cls_002'>
					<td class='before-spacer'></td>
					<td class='lien-line-item'>Cost of Obtaining Owner Information</td>
					<td class='lien-line-value'>$<span>{{ form.get('OwnerInfoCost').value | currency }}</span></td>
				</tr>
				<tr class='cls_002'>
					<td class='before-spacer'></td>
					<td class='lien-line-item'>Notification /Advertising Costs</td>
					<td class='lien-line-value'>$<span>{{ form.get('AdvertisingCosts').value | currency }}</span></td>
				</tr>
				<tr class='cls_002'>
					<td class='before-spacer'></td>
					<td class='lien-line-item'>Attorney's Fees (if applicable)</td>
					<td class='lien-line-value'>$<span>{{ form.get('AttorneyFees').value | currency }}</span></td>
				</tr>
				<tr class='cls_002'>
					<td class='before-spacer'></td>
					<td class='lien-line-item'>Prejudgement Interest</td>
					<td class='lien-line-value'>$<span>{{ form.get('PrejudgementInterest').value | currency }}</span></td>
				</tr>
				<tr class='cls_002'>
					<td class='before-spacer'></td>
					<td class='lien-line-item'>Court Costs/Certified Copies</td>
					<td class='lien-line-value'>$<span>{{ form.get('CourtCosts').value | currency }}</span></td>
				</tr>
				<tr class='cls_002 lien-total'>
					<td class='before-spacer'></td>
					<td class='lien-line-item underline'>TOTAL:</td>
					<td class='lien-line-value'>$<span>{{ getTotalCosts() | currency }}</span></td>
				</tr>
			</table>
			<div style="position:absolute;left:36.00px;top:480px" class="cls_002"><span class="cls_002">IT IS FURTHER ORDERED that the vehicle shall be sold at public auction to satisfy this lien in accordance with OCGA § 40-11-19.2.</span></div>
			<div style="position:absolute;left:68px;top:530px" class="cls_002"><span class="cls_002">
				SO ORDERED, this ___ day of __________, 20___.
			</span></div>
			<div style="position:absolute;left:327px;top:545px" class="cls_002"><span class="cls_002">
				_________________________________________
			</span></div>
			<div style="position:absolute;left:327px;top:560px" class="cls_002"><span class="cls_002">
				Magistrate
			</span></div>
			<div style="position:absolute;left:36.00px;bottom:80px" class="cls_007"><span class="cls_007">MAG 40-12</span></div>

		</div>
	</body></html>
</div><!-- end page 2 -->

        </div>
    </div>
</div>
</ng-template>
